import Vue from 'vue'
import VueRouter, {
  NavigationFailureType,
  isNavigationFailure
} from 'vue-router'

import store from '@/store/'
import { checkIsRouteWithFeatureEnabled } from '@/utils/feature-flags'

import adminRoutes from './admin'
import { boardingRoutes } from './boarding/boarding'
import { dashboardRoutes } from './dashboard'
import { diningHallRoutes } from './dining-hall'
import { infoboardsRoutes } from './infoboards'
import { settingsRoutes } from './settings'
import { studentContactsRoutes } from './student-contacts'
import { studentsRoutes } from './students'
import { TABS } from './tabs'
import teacherRoutes from './teacher'
import { teachersRoutes } from './teachers'
import { timetableRoutes } from './timetable'
import userRoutes from './user'

Vue.use(VueRouter)

const publicRoutes = [
  {
    path: '',
    component: () => import('@/views/Layouts/MiddleBox.vue'),
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import('@/views/Login/LoginView.vue'),
        meta: {
          public: true,
          title: 'Log in'
        }
      },
      {
        path: 'reset-password',
        component: () => import('@/views/PasswordReset/Reset/index.vue'),
        meta: {
          public: true,
          title: 'Reset password'
        }
      },
      {
        path: 'set-password/:uid/:token',
        name: 'PasswordResetFinish',
        component: () => import('@/views/PasswordReset/Finish/index.vue'),
        meta: {
          public: true,
          title: 'Set a password'
        }
      }
    ]
  },
  {
    path: '/ant-components',
    component: () => import('@/views/AntComponents.vue'),
    name: 'AntComponents',
    meta: {
      public: true
    }
  },
  {
    path: '/leave-request/approve',
    component: () => import('@/views/Attendance/LeaveRequest/Review/index.vue'),
    meta: {
      public: true,
      title: 'Review leave request'
    }
  }
]

const commonRoutes = [
  {
    path: '',
    redirect: '/teacher/timetable',
    component: () => import('@/views/Layouts/RouterView.vue'),
    children: [
      {
        name: 'course-list',
        path: 'courses',
        component: () => import('@/views/CourseList/CourseListView'),
        meta: {
          title: 'Courses',
          tab: TABS.academics,
          permissions: ['courses.view_course']
        }
      },
      {
        name: 'Cancelled lessons',
        path: 'cancelled-lessons',
        component: () =>
          import('@/views/CancelledLessons/CancelledLessonsView.vue'),
        meta: {
          title: 'Cancelled lessons',
          tab: TABS.academics,
          permissions: ['lesson_cancellation.view_cancellesson']
        }
      },
      {
        name: 'Course details',
        path: 'courses/:courseId',
        component: () => import('@/views/CourseDetails/index.vue'),
        children: [
          {
            path: 'details',
            name: 'course-details',
            component: () => import('@/views/CourseDetails/Details/index.vue'),
            meta: {
              title: 'Course details',
              tab: TABS.academics,
              permissions: ['courses.view_course']
            }
          },
          {
            path: 'students',
            name: 'course-details-students',
            component: () => import('@/views/CourseDetails/Students/index.vue'),
            meta: {
              title: 'Course students',
              tab: TABS.academics,
              permissions: ['courses.view_course']
            }
          }
        ]
      },
      {
        name: 'Alerts',
        path: 'alerts',
        component: () => import('@/views/Alerts/AlertsView.vue'),
        meta: {
          tab: TABS.academics,
          permissions: ['attendances.view_academicattendancealert'],
          title: 'Alerts'
        }
      },
      {
        name: 'Progress Alerts',
        path: 'progress',
        component: () => import('@/views/ProgressAlerts/index.vue'),
        meta: {
          tab: TABS.academics
        },
        children: [
          {
            name: 'current-potential-grades',
            path: 'current-potential-grades',
            component: () =>
              import('@/views/ProgressAlerts/CurrentPotentialGradesView.vue'),
            meta: {
              title: 'Current potential grades',
              tab: TABS.academics,
              permissions: ['permissions.view_potentialgradealert']
            }
          },
          {
            name: 'progress-comments',
            path: 'progress-comments',
            component: () =>
              import('@/views/ProgressAlerts/ProgressCommentList/index.vue'),
            meta: {
              title: 'Potential grades weekly report',
              tab: TABS.academics,
              permissions: ['permissions.view_progress_comments_summary_view']
            }
          }
        ]
      },
      {
        name: 'IDP Plans list',
        path: 'idp-plans/',
        component: () => import('@/views/IdpPlansList/IdpPlansListView.vue'),
        meta: {
          title: 'IDP Plans list',
          tab: TABS.academics,
          permissions: ['students_idp.view_studentidpplan']
        }
      },
      {
        name: 'Timetable Preview',
        path: 'timetable-preview/',
        component: () => import('@/views/TeacherPanel/TimetablePreview.vue'),
        meta: {
          title: 'Timetable Preview',
          tab: TABS.academics,
          permissions: [
            'draft_activities.view_draftactivity',
            'rota_activities.view_draftrotaactivity'
          ]
        }
      },
      {
        name: 'Timetable planner',
        path: 'planner/',
        redirect: { name: 'timetable-list' },
        component: () => import('@/views/Planner/index.vue'),
        meta: {
          permissions: ['remote_generator.view_generationtask'],
          tab: TABS.administration
        },
        children: [
          {
            path: 'timetables',
            component: () => import('@/views/Planner/TimetableList/index.vue'),
            name: 'timetable-list',
            meta: {
              title: 'Timetables & Boarding Rotas',
              tab: TABS.administration,
              permissions: ['timetable.view_timetable']
            }
          },
          {
            path: 'courses',
            component: () => import('@/views/Planner/CourseList/index.vue'),
            name: 'timetable-courses-list',
            meta: {
              title: 'Courses list',
              tab: TABS.administration,
              permissions: ['courses.view_course']
            }
          },
          {
            path: 'sections',
            component: () => import('@/views/Planner/SectionList/index.vue'),
            name: 'sections-list',
            meta: {
              title: 'Sections list',
              tab: TABS.administration,
              permissions: ['sections.view_section']
            }
          },
          {
            path: 'rooms',
            component: () => import('@/views/Planner/RoomList/index.vue'),
            name: 'rooms-list',
            meta: {
              title: 'Rooms list',
              tab: TABS.administration,
              permissions: ['rooms.view_room']
            }
          },
          {
            path: 'rooms/add',
            component: () => import('@/views/Planner/RoomForm/index.vue'),
            meta: {
              title: 'Room create',
              tab: TABS.administration,
              permissions: ['rooms.view_room']
            }
          },
          {
            path: 'rooms/:roomId',
            component: () => import('@/views/Planner/RoomForm/index.vue'),
            meta: {
              title: 'Room edit',
              tab: TABS.administration,
              permissions: ['rooms.view_room']
            }
          },
          {
            path: 'courses/add',
            component: () => import('@/views/Planner/CourseForm/index.vue'),
            meta: {
              title: 'Course create',
              tab: TABS.administration,
              permissions: ['remote_generator.view_generationtask']
            }
          },
          {
            path: 'courses/:courseId',
            component: () => import('@/views/Planner/CourseForm/index.vue'),
            meta: {
              title: 'Course edit',
              tab: TABS.administration,
              permissions: ['remote_generator.view_generationtask']
            }
          }
        ]
      },
      {
        path: 'planner/timetables/create',
        name: 'timetable-create',
        component: () => import('@/views/Planner/TimetableForm/'),
        meta: {
          title: 'Timetable create',
          tab: TABS.administration,
          permissions: ['remote_generator.view_generationtask']
        }
      },
      {
        path: 'timetables/:timetableId/optimizations/:rotaId?',
        name: 'timetable-optimizations',
        component: () => import('@/views/Planner/PotentialOptimizations/'),
        meta: {
          title: 'Timetable potential optimizations',
          tab: TABS.administration,
          permissions: ['remote_generator.view_generationtask']
        }
      },
      {
        path: 'timetables/:timetableId/free-slots/',
        component: () => import('@/views/Planner/FreeSlots/'),
        name: 'timetable-free-slots',
        meta: {
          title: 'Timetable free slots',
          tab: TABS.administration,
          permissions: ['draft_activities.view_draftactivity']
        }
      },
      {
        path: 'timetables/:timetableId/rooms-import/',
        name: 'timetable-rooms-import',
        component: () => import('@/views/Planner/RoomsImport/'),
        meta: {
          title: 'Import rooms from file',
          tab: TABS.administration,
          permissions: ['draft_activities.change_draftactivity']
        }
      },

      {
        path: 'timetables/generation/:generationTaskId',
        component: () => import('@/views/Planner/GenerationProcess/'),
        name: 'generation-process',
        meta: {
          title: 'Timetable generation process',
          tab: TABS.administration,
          permissions: ['remote_generator.view_generationtask']
        }
      },
      ...timetableRoutes,
      {
        path: 'planner/sections/add',
        name: 'add-section',
        component: () =>
          import('@/views/Planner/SectionForm/SectionFormView.vue'),
        meta: {
          title: 'Section create',
          tab: TABS.administration,
          permissions: ['remote_generator.view_generationtask']
        }
      },
      {
        path: 'planner/sections/:sectionId',
        name: 'edit-section',
        component: () =>
          import('@/views/Planner/SectionForm/SectionFormView.vue'),
        meta: {
          title: 'Section edit',
          tab: TABS.administration,
          permissions: ['remote_generator.view_generationtask']
        }
      },
      {
        path: 'houses/add',
        name: 'house-add',
        component: () => import('@/views/HouseForm/index.vue'),
        meta: {
          title: 'Create house',
          tab: TABS.administration,
          permissions: ['houses.add_house']
        }
      },
      {
        path: 'houses/:houseId',
        name: 'house-edit',
        component: () => import('@/views/HouseForm/index.vue'),
        meta: {
          title: 'Edit house',
          tab: TABS.administration,
          permissions: ['houses.change_house']
        }
      },
      {
        name: 'Houses',
        path: 'houses',
        component: () => import('@/views/HouseList/HouseListView.vue'),
        meta: {
          title: 'Houses list',
          tab: TABS.administration,
          permissions: ['houses.change_house']
        }
      },
      {
        name: 'Files for students',
        path: 'files-for-students',
        component: () =>
          import('@/views/DocumentsForApps/DocumentsForAppsView.vue'),
        meta: {
          title: 'Documents for apps list',
          tab: TABS.administration,
          permissions: ['files_for_students.view_fileforstudents']
        }
      },
      {
        path: 'billing-reports',
        name: 'billing-report-list',
        component: () =>
          import('@/views/AdminPanel/BillingReports/BillingReportsView.vue'),
        meta: {
          title: 'Billing reports list',
          tab: TABS.administration,
          permissions: ['billings.view_billingreport']
        }
      },
      {
        path: 'billing-reports/add',
        name: 'add-billing-report',
        component: () =>
          import(
            '@/views/AdminPanel/BillingReports/AddBillingReport/index.vue'
          ),
        meta: {
          title: 'Add billing report',
          tab: TABS.administration,
          permissions: ['billings.add_billingreport']
        }
      },
      {
        path: 'billing-reports/:id',
        name: 'billing-report-details',
        component: () =>
          import('@/views/AdminPanel/BillingReports/BillingReportDetail.vue'),
        meta: {
          title: 'Billing report details',
          tab: TABS.administration,
          permissions: ['billings.view_billingreport']
        }
      },
      {
        path: 'teams',
        name: 'team-list',
        component: () => import('@/views/Teams/TeamsView.vue'),
        meta: {
          title: 'Teams',
          tab: TABS.administration,
          permissions: ['teams.view_team']
        }
      },
      {
        path: '/doctors',
        component: () => import('@/views/Health/DoctorList/DoctorListView.vue'),
        name: 'Doctor List',
        meta: {
          title: 'Doctors List',
          tab: TABS.administration,
          permissions: ['doctors.view_doctor', 'doctors.change_doctor']
        }
      },
      {
        path: 'exam-planner/exam-sessions',
        component: () =>
          import('@/views/ExamPlanner/ExamSessionList/index.vue'),
        name: 'Exam Session List',
        meta: {
          title: 'Exam planner',
          tab: TABS.administration,
          permissions: ['exam_planner.view_examsession']
        }
      },
      {
        path: 'exam-planner/calendar/:id',
        component: () => import('@/views/ExamPlanner/ExamsPreview/index.vue'),
        name: 'Exam Session Timetable',
        meta: {
          title: 'Exam timetable',
          tab: TABS.administration,
          permissions: ['exam_planner.view_examsession']
        }
      },
      {
        path: 'exam-planner/exam-sessions/:id',
        component: () =>
          import('@/views/ExamPlanner/ExamSessionDetails/index.vue'),
        name: 'Exam Session Details',
        meta: {
          title: 'Exam planner',
          tab: TABS.administration,
          permissions: [
            'exam_planner.view_examsession',
            'exam_planner.view_exam'
          ]
        }
      },
      {
        path: 'exam-planner/exam-attendance/:examSession/:exam',
        component: () => import('@/views/ExamPlanner/ExamAttendance/index.vue'),
        name: 'ExamAttendance',
        meta: {
          title: 'Exam attendance',
          tab: TABS.academics
        }
      },
      {
        path: 'school-programmes/:programmeId/attendance-reports',
        component: () =>
          import(
            '@/views/Boarding/SchoolProgrammes/ProgrammeAttendance/ProgrammeAttendanceCollapse.vue'
          ),
        name: 'ProgrammeAttendance',
        meta: {
          title: 'Programme attendance',
          tab: TABS.academics
        }
      },
      ...dashboardRoutes,
      ...boardingRoutes,
      ...teachersRoutes,
      ...diningHallRoutes,
      ...infoboardsRoutes,
      ...settingsRoutes,
      ...studentsRoutes,
      ...studentContactsRoutes
    ]
  }
]

const wildcardRoutes = [
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/PageNotFound/PageNotFound.vue'),
    name: 'not-found'
  }
]

const privateRoutes = [
  {
    path: '',
    redirect: '/user/my-timetable',
    component: () => import('@/views/Layouts/SidebarContent.vue'),
    children: [
      ...commonRoutes,
      ...userRoutes,
      ...teacherRoutes,
      ...adminRoutes,
      ...wildcardRoutes
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes: [...publicRoutes, ...privateRoutes]
})

const routeGuard = async (to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title} — Rosenberg` : 'Rosenberg'

  const publicRoute = to.meta.public

  if (publicRoute) {
    next()
    return
  }

  const { profiles } = store.state.userInfo
  const { permissions } = store.state.userInfo

  if (!publicRoute && !profiles) {
    Vue.prototype.$notification.error({
      message: 'This page is only for logged in users. Log in!'
    })
    next({ name: 'Login', query: { next: to.path } })
    return
  }

  const routePermissions = to.meta?.permissions
  if (routePermissions) {
    const hasPermission = routePermissions.some(routePermission =>
      permissions.includes(routePermission)
    )

    if (!hasPermission || !checkIsRouteWithFeatureEnabled(to)) {
      Vue.prototype.$notification.error({
        message: "You don't have permission to view this page."
      })
      next({ name: 'Login' })
      return
    }
  }

  next()
}

router.beforeEach(routeGuard)

if (import.meta.env.PROD) {
  const handleDuplicatedNavigation = error => {
    if (!isNavigationFailure(error, NavigationFailureType.duplicated))
      throw error
  }

  const originalPush = router.push
  router.push = function push(to) {
    return originalPush.call(this, to).catch(handleDuplicatedNavigation)
  }

  const originalReplace = router.replace
  router.replace = function replace(to) {
    return originalReplace.call(this, to).catch(handleDuplicatedNavigation)
  }
}

export default router
